@import "../../components/constants/constants.scss";
#content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 2px auto;
  .header {
    margin-top: 40px;
    border-style: solid;
    border-width: 2px;
    border-left: none;
    border-right: none;
    border-image: $border-gradiant;
    width: 100%;
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 50px;
      margin: 5px 0;
      margin-bottom: 0;
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
  }
  .subHeader {
    display: flex;
    justify-content: start;
    gap: 16px;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    align-items: center;
    border-style: solid;
    border-width: 1px;
    width: 100%;
    padding-top: 15px;
    border-left: none;
    border-right: none;
    border-top: none;
    border-image: $border-gradiant;

    p {
      font-size: medium;
      font-weight: 200;
      padding: 0;
      margin: 0;
      @media screen and (max-width: 768px) {
        width: 95%;
        text-align: center;
      }
    }
    .tabButton {
      height: auto;
      padding: 15px 20px;
      background-color: #000;
      border-radius: 0;
      border: none;
      margin: 0;
      color: #fff;
      font-size: medium;
      &:hover {
        color: $pink;
      }
    }
    .tabButtonSelected {
      border: 1px solid gray;
      border-bottom: none;
    }
  }
  .nocontent {
    width: 100%;
    padding: 15px;
    background-color: #0009;
    border-style: solid;
    border-width: 2px;
    border-left: none;
    border-right: none;
    border-top: none;
    margin-bottom: 100px;
    border-image: $border-gradiant;
    .border {
      border: 1px solid #555;
      padding: 70px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 35px;
        margin-right: 5px;
      }
    }
  }
  .modelsGrid {
    display: flex;
    width: 100%;
    margin-bottom: 100px;
    border-style: solid;
    border-width: 2px;
    padding: 10px 0px;
    border-left: none;
    border-right: none;
    border-top: none;
    border-image: $border-gradiant;
    .loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 60px;
    }
    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      // padding: 0 20px;
      .tools {
        display: flex;
        flex-direction: row;
        align-items: center;
        a {
          img {
            height: 25px;
          }
        }
        div {
          display: flex;
          align-items: center;
          @-webkit-keyframes rotating /* Safari and Chrome */ {
            from {
              -webkit-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            to {
              -webkit-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @keyframes rotating {
            from {
              -ms-transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -o-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            to {
              -ms-transform: rotate(360deg);
              -moz-transform: rotate(360deg);
              -webkit-transform: rotate(360deg);
              -o-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          .rotating {
            -webkit-animation: rotating 2s linear infinite;
            -moz-animation: rotating 2s linear infinite;
            -ms-animation: rotating 2s linear infinite;
            -o-animation: rotating 2s linear infinite;
            animation: rotating 2s linear infinite;
          }

          img {
            user-select: none;
            margin: 10px;
            height: 40px;
          }
          span {
            user-select: none;
            color: $pink;
          }
        }
      }
    }
  }
}
