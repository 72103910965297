@import "../../components/constants/constants.scss";
$margin: 15px;
#signin {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .header {
    margin-top: 100px;
    text-align: center;
    width: 100%;
    img{
      width: 100%;
      @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
    h1 {
      @media screen and (max-width: 768px) {
        font-size: 22px;
      }
    }
  }
  .form {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    label {
      margin-bottom: $margin;
      margin-top: $margin;
      font-size: 13px;
    }
    .error,
    .error2 {
      color: red;
      display: inline-flex;
      position: absolute;
      right: 0;
      width: 70%;
      img {
        width: 15px;
        height: 15px;
        padding: 15px;
      }
      label {
        margin-bottom: 15px;
        margin-top: 15px;
        font-size: 13px;
      }
    }
    .error {
      top: 70px;
    }
    .error2 {
      top: 150px;
    }
    input {
      background-color: transparent;
      border: 1px solid rgba($color: #fff, $alpha: 0.5);
      transition: all 0.2s ease-in;
      width: 100%;
      color: white;
      padding: 3px;
      font-family: Arial;
    }
    button {
      margin-top: $margin;
      cursor: pointer;
      &:active {
        color: $pink;
        border: 1px solid $pink
      }
    }
  }
}
