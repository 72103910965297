@import "../../components/constants/constants.scss";
.body {
  min-height: 90vh;
  .open {
    height: 45px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
  }
  nav {
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(238, 231, 231, 0.5);
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
    height: 140px;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    .logo {
      @media screen and (max-width: 768px) {
        padding-top: 10px;
      }
      img {
        height: 20px;
      }
    }
    .menu {
      h4 {
        padding: 0px;
        margin: 0px;
      }
      a {
        color: $pink;
        text-decoration: none;
        outline: none;
        transition: color 0.2s ease;
        font-size: 14px;
        &:hover {
          color: white;
        }
      }
      ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        @media screen and (max-width: 768px) {
          justify-content: flex-start;
          padding-left: 10px;
          flex-direction: column;
          padding-left: 0;
        }
        li {
          a {
            margin: 10px;
            color: $pink;
            text-decoration: none;
            outline: none;
            transition: color 0.2s ease;
            font-size: 14px;
            &:hover {
              color: white;
            }
          }
        }
        .icon {
          position: absolute;
          top: 5px;
          right: 5px;
          @media screen and (min-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
  main {
    min-height: 90vh;
    width: 50%;
    margin: 2px auto;
    @media screen and (max-width: 768px) {
      width: 95%;
    }
    &.sign-in{
        min-height: 83vh;
    }
  }
  footer {
    width: 100%;
    border-top: 1px solid #fff;
    padding: 16px 0;
    max-height: 50px;
    div {
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        span {
          font-size: 10px;
        }
        div {
          a {
            text-decoration: none;
            color: white;
            font-size: 5px;
            letter-spacing: 1px;
          }
          span {
            margin: 0 3px;
            font-size: 5px;
          }
        }
      }
      .social {
        display: flex;
        flex-direction: row;
        a {
          margin-right: 3px;
          svg {
            path {
              color: grey;
              &:hover {
                color: "pink";
              }
            }
          }
        }
      }
    }
  }
}
button {
  background-color: transparent;
  color: $pink;
  border-radius: 20px;
  padding: 6px 20px;
  border: 2px solid $pink;
  outline: none;
  transition: all 0.2s ease-in-out;
  height: 35px;
  cursor: pointer;
  &:hover {
    border-color: white;
    color: white;
  }
}
