.UploadTypeDialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.UploadTypeDialogContent__heading {
  text-align: center;
}

.UploadTypeDialogContent__subheading {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}
