.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure it appears on top of other elements */
}

.loading-content {
  display: flex;
  align-items: center;
}

.spinner {
  /* Adjust the spinner size if needed */
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.loading-text {
  margin-left: 10px; /* Add some spacing between the image and text */
  font-family: Arial, sans-serif;
  font-size: 32px; /* Adjust font size as needed */
  font-weight: bold;
  font-style: italic;
  color: #EFABFF;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}